import PropTypes from 'prop-types'
import React from 'react'
import odderjobsIntro from '../images/odderjobs-intro.jpg'
import odderJobsAbout from '../images/odderjobs-about.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Intro</h2>
          <span className="image main">
            <img src={odderjobsIntro} alt="" />
          </span>
          <p>
          Two Bowling Green State University students serving on the Association for Computer Machinery board, 
          Spencer and Vagish, came together to participate in the HackOHI/O 2020 hackathon. 
          They made a simple communications relay tool using SMS. While doing that, 
          they built out the beginning plan for OdderJobs. 
          OdderJobs is a device-agnostic handyman scheduling tool that is easy for any group to use using the method they prefer the most.
          </p>
          <p>
            OdderJobs is being built part-time by Spencer and Vagish, and is expected to launch in Q3 2021.
          </p>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <span className="image main">
            <img src={odderJobsAbout} alt="" />
          </span>
          <p>
            OdderJobs is about solving the fundamental issue of being able to get a handyman's help. 
            We wanted to do it while making sure the handymen could use their current method of communication while opening up more opportunities for them. At the same time, 
            we want to provide a multi-faceted device agnostic toolset for the homeowner or renter to use when they need help.
          </p>
          <p>
            OdderJobs will work through text messaging, calling, an app, and the web. 
            This allows the customer to use the system they are most comfortable with and reduces the learning barrier of technology.
          </p>
          <p>
            OdderJobs was founded by Spencer Wolf and Vagish Vela. 
            Vagish Vela has over a decade of experience in the digital marketing space and software engineering. 
            He has also worked on both market and product strategy for a Fortune 500 company.
          </p>
          {close}
        </article>

        <article
          id="sign-up"
          className={`${this.props.article === 'sign-up' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Sign Up</h2>
          <p>
            Coming soon!
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form id="contactForm" method="post" name="contact" action="/thank-you" netlify-honeypot="bot-field" data-netlify="true">
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          {/* <ul className="icons">
            <li>
              <a
                href="https://twitter.com/HuntaroSan"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/codebushi/gatsby-starter-dimension"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul> */}
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
